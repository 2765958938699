import {listen} from "../utils";

const Selector = {
    NEEDS_TIMESTAMP: '.needs-timestamp',
};

// Add timestamp to URL
listen('click', Selector.NEEDS_TIMESTAMP, (event, target) => {
    event.preventDefault()
    const hrefValue = target.href;
    const url = new URL(hrefValue, window.location.origin);
    url.searchParams.append('ts', `${Date.now()}`);
    window.location.href = url.toString();
})