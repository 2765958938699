import {listen, validateCodingSequence} from "../utils";

const Selector = {
    MODAL: '#modal',
    FILE_INPUT: '.custom-file-input',
    RESET_BUTTON: 'button[type="reset"]',
    CODING_SEQUENCE_FORM_START: '.coding-sequence-form #id_start',
    CODING_SEQUENCE_FORM_END: '.coding-sequence-form #id_end',
    PERCENT_DIFF_TARGET: 'data-percent-diff-target',
    DISABLE_TARGET_ID: '[data-disable-target-id]'
};


// Validate coding sequence when start changes
listen('change', Selector.CODING_SEQUENCE_FORM_START, (event, target) => {
    const endEl = target.form.querySelector(Selector.CODING_SEQUENCE_FORM_END)
    validateCodingSequence(target, endEl)
})

// Validate coding sequence when end changes
listen('change', Selector.CODING_SEQUENCE_FORM_END, (event, target) => {
    const startEl = target.form.querySelector(Selector.CODING_SEQUENCE_FORM_START)
    validateCodingSequence(startEl, target)
})

listen('click', Selector.RESET_BUTTON, (event, target) => {
    let input = target.closest(Selector.FILE_INPUT);
    if (input) {
        input.value = '';
        input.dispatchEvent(new Event('change'));
    }
    if (window.location.search)
        window.location.replace(location.pathname);
})

listen('change', `[${Selector.PERCENT_DIFF_TARGET}]`, (event, target) => {
    const diffTargetID = target.dataset.percentDiffTarget;
    const diffTarget = document.getElementById(diffTargetID)
    const items = document.querySelectorAll(`[${Selector.PERCENT_DIFF_TARGET}="${diffTargetID}"]`)
    const total = [...items].reduce((acc, curr) => acc + parseInt(curr.value || 0), 0);
    items.forEach((item) => {
        if (item.value !== '') {
            item.classList.toggle('is-invalid', total > 100)
            item.classList.toggle('is-valid', total <= 100)
        }
    })
    if (total <= 100) {
        diffTarget.value = 100 - total
    }
})

listen('click', Selector.DISABLE_TARGET_ID, (event, target) => {
    let el = document.getElementById(target.dataset.disableTargetId);
    if (el) {
        el.disabled = !target.checked
    }
})
