/**
 * Download a DOM portion (node) as an image.
 *
 * @param nodeId            ID of the DOM node that represents the portion to download.
 * @param imageFormat       Downloaded format. Available formats are 'png', 'jpg' or 'svg'.
 * @param imageName         Name of the downloaded image, without format.
 * @param classesToExclude  String containing the classes that mark the nodes that must be excluded.
 *                          The classes are space-separated. Example: "class-1 class-2 class-3".
 *                          null (default) means nothing to exclude.
 */
function downloadDomImage(nodeId, imageFormat, imageName, classesToExclude = null) {
    Promise.all([
        import('html-to-image'),
        import('downloadjs'),
    ]).then(([htmlToImage, downloadJs]) => {
        const download = downloadJs.default;
        const el = document.getElementById(nodeId);

        // Define the filter function based on the classes to exclude
        let filterFunc = null;
        if (classesToExclude) {
            filterFunc = function(node) {
                if (node.getAttribute !== undefined) {
                    // Get the array of the classes of current node (white spaces are removed)
                    let currentArray =
                        node.getAttribute('class') ?
                            node.getAttribute('class').split(/(\s+)/).filter(e => e.trim().length > 0) : [];
                    // Get the array of the classes to exclude (white spaces are removed)
                    let excludeArray = classesToExclude.split(/(\s+)/).filter(e => e.trim().length > 0);
                    // Current node is included if it has no classes that must be excluded
                    return (currentArray.filter(x => excludeArray.includes(x)).length === 0);
                } else {
                    return true;
                }
            }
        }

        const downloadOptions = {filter: filterFunc, style: { background: "white" }};

        // Proceed with image download
        switch (imageFormat) {
            case 'png':
                htmlToImage.toPng(el, downloadOptions)
                    .then(function (dataUrl) {
                        download(dataUrl, imageName + '.' + imageFormat);
                    });
                break;
            case 'jpg':
            case 'jpeg':
                htmlToImage.toJpeg(el, downloadOptions)
                    .then(function (dataUrl) {
                        download(dataUrl, imageName + '.' + imageFormat);
                    });
                break;
            case 'svg':
                htmlToImage.toSvg(el, downloadOptions)
                    .then(function (dataUrl) {
                        download(dataUrl, imageName + '.' + imageFormat);
                    });
                break;
            default:
                break;
        }
    })
}


// Iterate over all elements (buttons, links, etc) that handle a DOM portion download,
// that is all the elements marked with '.dom-download' as class.
// The information about the node to download, as well as image format and name,
// is taken from the element's dataset.
document.addEventListener("DOMContentLoaded", () => {
    const NAME = '.dom-download';
    document.querySelectorAll(NAME)
        .forEach((el) => {
            if (el.getAttribute('listener') !== 'true') {
                const nodeId = el.dataset['nodeId'];
                const imageFormat = el.dataset['imageFormat'];
                const imageName = el.dataset['imageName'];
                const classesToExclude =
                    el.hasAttribute("data-classes-to-exclude")?
                        el.dataset['classesToExclude'] : null;
                el.setAttribute('listener', 'true');
                el.addEventListener('click', () => {
                    console.log(nodeId, imageFormat, imageName, classesToExclude)
                    downloadDomImage(nodeId, imageFormat, imageName, classesToExclude);
                })
            }
        })
})