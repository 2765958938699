import {listen} from "../utils";
import {Modal} from "bootstrap"

export const Selector = {
    MODAL: '#modal',
    AJAX_FORM: '.ajax-form',
    AJAX_SUBMIT_BUTTON: '.btn-ajax-submit',
};

function doAjaxSubmit(form, action = undefined) {
    // Bind the FormData object and the form element
    const FD = new FormData(form);
    form.classList.add('busy')
    const buttons = form.querySelectorAll('[type="submit"]')
    buttons.forEach((btn) => {
        btn.disabled = true
    })
    import('../utils/axios').then((module) => {
        return module.axios({
            baseURL: '',
            url: action || form.action,
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest'
            },
            data: FD,
            method: 'post'
        }).catch((errors) => {
            form.querySelectorAll('.error').forEach((el) => {
                el.remove()
            });
            for (const [fieldName, messages] of Object.entries(errors.response.data)) {
                const fields = document.getElementsByName(fieldName)
                if (fields.length > 0) {
                    fields.forEach((field) => {
                        field.classList.add('is-invalid')
                        messages.forEach((msg => {
                            const fieldError = document.createElement('div')
                            fieldError.className = 'error invalid-feedback'
                            fieldError.textContent = msg
                            field.parentNode.append(fieldError)
                        }))
                    })
                } else {
                    messages.forEach((msg => {
                        const formAlert = document.createElement('div')
                        formAlert.className = 'error alert alert-danger'
                        formAlert.textContent = msg
                        form.prepend(formAlert)
                    }))
                }
            }
        }).then((response) => {
            if (response) {
                const $modal = form.closest(Selector.MODAL)
                if ($modal) {
                    const bsModal = Modal.getInstance($modal);
                    bsModal.hide();
                }
                if (response.data.success_url) {
                    window.location = response.data.success_url
                }
            }
        }).finally(() => {
            form.classList.remove('busy')
            buttons.forEach((btn) => {
                btn.disabled = false
            })
        })
    })
}

listen('submit', Selector.AJAX_FORM, (event, target) => {
    if (target && target.checkValidity()) {
        event.preventDefault();
        doAjaxSubmit(target)
    }
})


listen('click', Selector.AJAX_SUBMIT_BUTTON, (event, target) => {
    if (target.form && target.form.checkValidity()) {
        event.preventDefault();
        doAjaxSubmit(target.form, target.formAction)
    }
})
