import {listen, setCookie} from "../utils";

const Selector = {
    SIDEBAR_WRAPPER: '#wrapper',
    SIDEBAR_TOGGLER: '#sidebarToggler',
}

// Toggle sidebar and set toggle status cookie
listen('click', Selector.SIDEBAR_TOGGLER, (event, target) => {
    event.preventDefault()
    const sidebarWrapper = document.querySelector(Selector.SIDEBAR_WRAPPER)
    sidebarWrapper.classList.toggle('toggled')
    target.classList.toggle('active')
    setCookie('sidebartoggled', sidebarWrapper.classList.contains('toggled'))
})

