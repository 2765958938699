import {downloadSeriesCsv} from "./series";


document.addEventListener('DOMContentLoaded', () => {
    const csvSelector = '.series-csv-download';
    document.querySelectorAll(csvSelector).forEach((csvEl) => {
        (async () => {
            let chartEl = document.getElementById(csvEl.dataset.nodeId);
            let series = null;
            if (chartEl.hasAttribute('data-series-url')) {
                let response = await fetch(chartEl.dataset.seriesUrl);
                if (response.ok) {
                    series = await response.json();
                }
            } else if (chartEl.hasAttribute('data-series-json')) {
                series = JSON.parse(chartEl.dataset.seriesJson);
            }
            if (series) {
                let fileName = `${csvEl.dataset.fileName}.csv`;
                csvEl.addEventListener('click', () => {
                    downloadSeriesCsv(series, fileName);
                });
            }
        })();
    })
});