/*
Add autocomplete features to DOM elements.

Data attributes:
- data-autocomplete-url: specify the API url to fetch data (required)
- data-autocomplete-keys: a JSON data that specify the keys for navigating the API response
- data-autocomplete-labels: a JSON data to map key into label (e.g. friendly_id to part id)

*/

const AUTOCOMPLETE_SELECTOR = '[data-autocomplete-url]'

document.addEventListener("DOMContentLoaded", function () {
    const autocompleteEl = document.querySelector(AUTOCOMPLETE_SELECTOR);
    if (autocompleteEl) {
        autocompleteEl.addEventListener("keydown", e => {
            if (e.key === "Enter") {
                autocompleteEl.form.submit()
            }
        })
        const keys = autocompleteEl.dataset.autocompleteKeys ? JSON.parse(autocompleteEl.dataset.autocompleteKeys) : ["name", 'friendly_id'];
        const labels = autocompleteEl.dataset.autocompleteLabels ? JSON.parse(autocompleteEl.dataset.autocompleteLabels) : {name: "Name", friendly_id: "Part ID."};
        import('@tarekraafat/autocomplete.js').then((module) => {
                const autoCompleteJS = new module.default({
                    selector: AUTOCOMPLETE_SELECTOR,
                    trigger: (query) => {
                        return query.replace(/ /g, "").length; // Returns "Boolean"
                    },
                    debounce: 300, // Milliseconds value
                    data: {
                        src: async (query) => {
                            try {
                                // Fetch External Data Source
                                const sourceURL = autocompleteEl.dataset.autocompleteUrl;
                                const source = await fetch(`${sourceURL}?q=${query}`);
                                const data = await source.json();
                                // Returns Fetched data
                                return data.results;
                            } catch (error) {
                                return error;
                            }
                        },
                        keys: keys,
                    },
                    resultItem: {
                        element: (item, data) => {
                            const label = labels[data.key] || data.key
                            // Modify Results Item Content
                            item.innerHTML = `
                            <div class="d-flex align-items-center justify-content-between autoComplete_result">
                                <span class="text-truncate me-4 text-dark">${data.match}</span>
                                <small class="opacity-50 text-capitalize">${label}</small> 
                            </div>
                            `
                        },
                        highlight: true,
                    },
                    events: {
                        input: {
                            focus() {
                                if (autoCompleteJS.input.value.length) autoCompleteJS.start();
                            },
                            selection(event) {
                                const feedback = event.detail;
                                autoCompleteJS.input.blur();
                                // Prepare User's Selected Value
                                const selection = feedback.selection.value[feedback.selection.key];
                                // Render selected choice to selection div
                                autocompleteEl.value = selection;
                                // Replace Input value with the selected value
                                autoCompleteJS.input.value = selection;
                                // Submit form
                                autocompleteEl.form.submit();
                            },
                        },
                    },
                })
            }
        )
    }
})
