import ReconnectingWebSocket from "reconnecting-websocket";
import * as Sentry from '@sentry/browser';

import '../scss/main.scss'

import {listen, setCookie} from "./utils";

import "./modules/autocomplete"
import './modules/modal'
import './modules/ajax'
import './modules/domDownload'
import './modules/sidebar'
import './modules/form'
import './modules/timestamp'
import './modules/charts/index'
import './modules/csv/index'
import './modules/discount'

if (process.env.SENTRY_DSN) {
    const APP_VERSION = `${process.env.APP_VERSION}`
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        release: `${process.env.SENTRY_RELEASE_NAME}@${APP_VERSION}`,
        integrations: [Sentry.browserTracingIntegration()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE || 1.0,
        environment: process.env.ENVIRONMENT_TYPE || 'production',
    });
}

const Selector = {
    LIST_VIEW: '[data-list-view]',
    EDITING_MESSAGE: '.editing-message',
    NEEDS_EDITING: '.needs-editing',
    COUPON: '.coupon',
    BILL: '.bill',
    GO_BACK: '[data-go-back]'
};


document.addEventListener("DOMContentLoaded", function () {

    // Toggle list/card view cookie
    listen('click', Selector.LIST_VIEW, (event, target) => {
        setCookie('listview', target.dataset.listView);
        location.reload();
    })

    listen('click', Selector.GO_BACK, (event, target) => {
        event.preventDefault();
        history.back();
    })

    const $editingMessage = document.querySelector(Selector.EDITING_MESSAGE);
    if ($editingMessage && $editingMessage.dataset.wsPath) {
        let wsScheme = window.location.protocol === "https:" ? "wss" : "ws",
            wsPath = wsScheme + '://' + window.location.host + $editingMessage.dataset.wsPath,
            userID = parseInt($editingMessage.dataset.userId),
            content = $editingMessage.dataset.content,
            $needsEditing = document.querySelector(Selector.NEEDS_EDITING),
            socket = new ReconnectingWebSocket(wsPath);
        socket.onmessage = function (message) {
            const data = JSON.parse(message.data);
            const isEditing = data.editor && data.editor.id !== userID
            if ($needsEditing)
                $needsEditing.classList.toggle('disabled', isEditing);
            $editingMessage.classList.toggle('d-none', !isEditing);
            if (isEditing) {
                $editingMessage.innerHTML = content.replace('{editor}', data.editor.name)
            }
        }
    }

    const $bill = document.querySelector(Selector.BILL)
    if ($bill)
        import('./modules/bill').then((e) => {
            const bill = e.default($bill)
            const $coupon = document.querySelector(Selector.COUPON)
            if ($coupon)
                import('./modules/coupon').then((e) => {
                    e.default(bill, $coupon)
                })
        })

    // Enable tourguide.js (https://tourguidejs.com/)
    const tourTrigger = document.getElementById('tourTrigger')
    if (tourTrigger) {
        import("@sjmc11/tourguidejs").then((module) => {
            const group = tourTrigger.dataset.tgGroup
            const tg = new module.TourGuideClient({
                completeOnFinish: true,
                dialogZ: 1100,
                backdropClass: "z-index-toast",
                exitOnClickOutside: false
            })
            if (!tg.isFinished(group))
                tg.start(group)
        })
    }
});
