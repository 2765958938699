export const downloadSeriesCsv = (series, fileName) => {
    const zipData = (arr, lst, field) => {
        for (let i = 0; i < arr.length; i++) {
            if (i >= lst.length) {
                lst.push({});
            }
            lst[i][field] = arr[i];
        }
    }
    const buildColumnName = (generalSeries, singleSeries, field) => {
        let name = '';
        if (singleSeries.hasOwnProperty('code_name')) {
            name += singleSeries.code_name + ' ';
        } else if (generalSeries.hasOwnProperty('code_name')) {
            name += generalSeries.code_name + ' ';
        }
        if (singleSeries.hasOwnProperty(field)) {
            name += singleSeries[field];
        } else if (generalSeries.hasOwnProperty(field)) {
            name += generalSeries[field] + ' ';
        }
        return name;
    };

    let fields = [];
    let data = [];
    let xi = 0, yi = 0;
    let seriesList = series.hasOwnProperty('series') ? series.series : [series];
    fields.push({
        value: 'x',
        label: buildColumnName(series, series, 'x_name'),
    });
    zipData(series.x_values, data, 'x');
    seriesList.forEach(s => {
       if (s.hasOwnProperty('x_values')) {
           xi++;
           fields.push({
               value: `x_${xi}`,
               label: buildColumnName(series, s, 'x_name'),
           });
           zipData(s.x_values, data, `x_${xi}`);
       }
       if (s.hasOwnProperty('y_values')) {
           yi++;
           fields.push({
               value: `y_${yi}`,
               label: buildColumnName(series, s, 'y_name'),
           });
           zipData(s.y_values, data, `y_${yi}`);
       }
    });
    if (xi > 0) {
        fields.shift();
    }

    Promise.all([
        import('json2csv'),
        import('downloadjs'),
    ]).then(([json2csv, downloadJs]) => {
        const download = downloadJs.default;
        let csv = json2csv.parse(data, { fields });
        download(csv, fileName, 'text/plain');
    });
};