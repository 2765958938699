const Selector = {
    INPUT_DISCOUNT: 'input[name="discount"]',
    INPUT_PRICE: '#id_price_0',
}

function renderDiscountPreview($price, $discountPerc, $previewDiscount, $previewPrice) {
    const priceVal = parseFloat($price ? $price.value : 0.0)
    const discountRate = parseFloat($discountPerc.value) / 100
    const discountVal = priceVal * discountRate
    const discountedPrice = priceVal - discountVal
    $previewDiscount.textContent = (discountVal || 0).toFixed(2)
    $previewPrice.textContent = (discountedPrice || 0).toFixed(2)
}

const $inputPrice = document.querySelector(Selector.INPUT_PRICE)
const $inputDiscount = document.querySelector(Selector.INPUT_DISCOUNT)
if ($inputPrice && $inputDiscount) {
    const $preview = document.createElement('small')
    $preview.className = 'opacity-50 mt-2'
    const span1 = $preview.appendChild(document.createElement('span'))
    span1.textContent = 'Discounted price: '
    span1.className = 'text-nowrap'
    const $previewPrice = span1.appendChild(document.createElement('strong'))
    const span2 = $preview.appendChild(document.createElement('span'))
    span2.textContent = 'Discount value: '
    span2.className = 'ms-2 text-nowrap'
    const $previewDiscount = span2.appendChild(document.createElement('strong'))
    renderDiscountPreview($inputPrice, $inputDiscount, $previewDiscount, $previewPrice);
    $inputPrice.parentNode.insertAdjacentElement('afterend', $preview);
    ['input', 'change'].forEach(eventName => {
        [$inputPrice, $inputDiscount].forEach(el => {
            el.addEventListener(eventName, event => {
                renderDiscountPreview($inputPrice, $inputDiscount, $previewDiscount, $previewPrice)
            })
        })
    })
}

