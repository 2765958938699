import {Modal} from "bootstrap";

const Selector = {
    MODAL: '#modal',
    FIRST_TEXT_INPUT: '.modal-dialog form textarea, .modal-dialog form input[type="text"]:not([readonly])',
};


document.addEventListener("DOMContentLoaded", function () {
    const $modal = document.querySelector(Selector.MODAL);
    $modal.addEventListener('shown.bs.modal', (e) => {
        const el = document.querySelector(Selector.FIRST_TEXT_INPUT)
        if (el) {
            // Focus first input
            el.focus()
            // and put cursor at the end
            const value = el.value;
            el.value = "";
            el.value = value;
        }
    });

    $modal.addEventListener('toggle.bs.modal', (e) => {
        const bsModal = Modal.getInstance($modal)
        bsModal.toggle()
    })
})