let style = getComputedStyle(document.body);

export const theme = {
    colors: {
        primary: style.getPropertyValue('--bs-primary'),
        secondary: style.getPropertyValue('--bs-secondary'),
        success: style.getPropertyValue('--bs-success'),
        info: style.getPropertyValue('--bs-info'),
        warning: style.getPropertyValue('--bs-warning'),
        danger: style.getPropertyValue('--bs-danger'),
        light: style.getPropertyValue('--bs-light'),
        dark: style.getPropertyValue('--bs-dark'),
        gray_400: style.getPropertyValue('--bs-gray-400'),
        gray_500: style.getPropertyValue('--bs-gray-500')
    }
};
