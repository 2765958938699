import {drawSeriesChart} from "./series";


document.addEventListener('DOMContentLoaded', () => {
    const chartSelector = "[data-series-chart]"
    document.querySelectorAll(chartSelector).forEach((el) => {
        (async () => {
            let series = null;
            if (el.hasAttribute('data-series-url')) {
                let response = await fetch(el.dataset.seriesUrl);
                if (response.ok) {
                    series = await response.json();
                }
            } else if (el.hasAttribute('data-series-json')) {
                series = JSON.parse(el.dataset.seriesJson);
            }
            if (series) {
                const chartConfigParams = [
                    'maintainAspectRatio',
                    'flipAxes',
                    'lineColors',
                    'areaColors',
                    'lineWidth',
                    'lineTension',
                    'pointRadius',
                    'labelAxisPrefix',
                    'labelAxisSuffix',
                    'valueAxisForceRange',
                    'valueAxisMin',
                    'valueAxisMax',
                    'valueAxisPrefix',
                    'valueAxisSuffix',
                    'valueAxisPrecision',
                    'highlightValueRangeDisplay',
                    'highlightValueRangeMin',
                    'highlightValueRangeMax',
                    'highlightValueRangeLineWidth',
                    'highlightValueRangeLineColor',
                    'highlightValueRangeAreaColor',
                    'highlightValueRangeMarkInOut',
                    'highlightValueRangeInColor',
                    'highlightValueRangeOutColor',
                    'titleDisplay',
                    'titleFontSize',
                    'titleOverwriting',
                    'subtitleDisplay',
                    'subtitleFontSize',
                    'subtitleOverwriting',
                    'legendDisplay',
                    'legendFontSize',
                    'axisNameDisplay',
                    'axisNameFontSize',
                    'ticksFontSize',
                    'opacity',
                ];
                let config = {};
                chartConfigParams.forEach(attr => {
                    if (el.dataset.hasOwnProperty(attr)) {
                        try {
                            config[attr] = JSON.parse(el.dataset[attr]);
                        } catch (error) {}
                    }
                })
                drawSeriesChart(el.dataset.seriesChart, el, series, config);
            }
        })();
    })
});