import {theme} from '../../utils/theme'

const chartPadding = 3;

const chartIsCartesian = (type) => {
    return ['line', 'bar', 'bubble'].includes(type);
}

const chartIsCake = (type) => {
    return ['pie', 'doughnut'].includes(type);
}

const chartHasPoints = (type) => {
    return ['line', 'radar', 'bubble'].includes(type);
}

const chartHasLine = (type) => {
    return ['line', 'bar'].includes(type);
}

const chartHasArea = (type) => {
    return ['bar', 'radar', 'pie', 'doughnut'].includes(type);
}

const parseColor = (color) => {
    if (Object.keys(theme.colors).some(c => c === color)) {
        return theme.colors[color];
    } else {
        return color;
    }
}

const parseColors = (colors) => {
    let parsedColors = [];
    colors.forEach(c => {
        parsedColors.push(parseColor(c));
    });
    return parsedColors;
}


class BaseChart {
    type;
    context;
    series;
    config;
    options = {};
    datasets = [];
    chart;
    /**
     * Saves chart parameters.
     */
    constructor(
        type,
        context,
        series,
        {
            responsive = true,
            maintainAspectRatio = false,
            flipAxes = false,

            lineColors = ['rgb(0, 0, 0, 0.5)'],
            areaColors = ['rgb(0, 0, 0, 0.1)'],

            lineWidth = 1,
            lineTension = 0,

            pointRadius = 2,

            labelAxisPrefix = '',
            labelAxisSuffix = '',

            valueAxisForceRange = false,
            valueAxisMin = 0,
            valueAxisMax = 100,
            valueAxisPrefix = '',
            valueAxisSuffix = '',
            valueAxisPrecision = 0,

            highlightValueRangeDisplay = false,
            highlightValueRangeMin = 0,
            highlightValueRangeMax = 0,
            highlightValueRangeLineWidth = 1,
            highlightValueRangeLineColor = 'rgb(0, 191, 165)',
            highlightValueRangeAreaColor = 'rgb(0, 191, 165, 0.2)',
            highlightValueRangeMarkInOut = false,
            highlightValueRangeInColor = 'rgb(0, 0, 0, 0.5)',
            highlightValueRangeOutColor = 'rgb(0, 0, 0, 0.5)',

            titleDisplay = true,
            titleFontSize = 16,
            titleOverwriting = null,

            subtitleDisplay = true,
            subtitleFontSize = 14,
            subtitleOverwriting = null,

            legendDisplay = true,
            legendFontSize = 14,

            axisNameDisplay = true,
            axisNameFontSize = 14,

            ticksFontSize = 14,

            opacity = 1.0,
        }
    ) {
        this.type = type;
        this.context = context;
        this.series = series;
        this.config = {
            responsive: responsive,
            maintainAspectRatio: maintainAspectRatio,
            flipAxes: flipAxes,

            lineColors: parseColors(lineColors),
            areaColors: parseColors(areaColors),

            lineWidth: lineWidth,
            lineTension: lineTension,

            pointRadius: pointRadius,

            labelAxisPrefix: labelAxisPrefix,
            labelAxisSuffix: labelAxisSuffix,

            valueAxisForceRange: valueAxisForceRange,
            valueAxisMin: valueAxisMin,
            valueAxisMax: valueAxisMax,
            valueAxisPrefix: valueAxisPrefix,
            valueAxisSuffix: valueAxisSuffix,
            valueAxisPrecision: valueAxisPrecision,

            highlightValueRangeDisplay: highlightValueRangeDisplay,
            highlightValueRangeMin: highlightValueRangeMin,
            highlightValueRangeMax: highlightValueRangeMax,
            highlightValueRangeLineWidth: highlightValueRangeLineWidth,
            highlightValueRangeLineColor: parseColor(highlightValueRangeLineColor),
            highlightValueRangeAreaColor: parseColor(highlightValueRangeAreaColor),
            highlightValueRangeMarkInOut: highlightValueRangeMarkInOut,
            highlightValueRangeInColor: parseColor(highlightValueRangeInColor),
            highlightValueRangeOutColor: parseColor(highlightValueRangeOutColor),

            titleDisplay: titleDisplay,
            titleFontSize: titleFontSize,
            titleOverwriting: titleOverwriting,

            subtitleDisplay: subtitleDisplay,
            subtitleFontSize: subtitleFontSize,
            subtitleOverwriting: subtitleOverwriting,

            legendDisplay: legendDisplay,
            legendFontSize: legendFontSize,

            axisNameDisplay: axisNameDisplay,
            axisNameFontSize: axisNameFontSize,

            ticksFontSize: ticksFontSize,

            opacity: opacity,
        };
    }

    _tooltipTitleCallback(ctx) {
        return ctx[0].dataset.label;
    }

    _tooltipLabelCallback(ctx) {
        return `${ctx.dataset.dataLabels[ctx.dataIndex]}: ${ctx.formattedValue}`;
    }

    /**
     * Builds chart options structure.
     */
    _buildOptions() {
        this.options = {
            maintainAspectRatio: this.config.maintainAspectRatio,
            animation: {
                duration: 400,
            },
            layout: {
                padding: {
                    top: 0,
                },
            },
            plugins: {
                title: {
                    display: this.config.titleDisplay,
                    font: {
                        size: this.config.titleFontSize,
                        weight: 'bold',
                    },
                    text: this.config.titleOverwriting !== null ? this.config.titleOverwriting : this.series.description,
                },
                subtitle: {
                    display: this.config.subtitleDisplay,
                    font: {
                        size: this.config.subtitleFontSize,
                        weight: 'bold',
                    },
                    text: this.config.subtitleOverwriting !== null ? this.config.subtitleOverwriting : this.series.insights,
                    padding: {
                        bottom: 10,
                    }
                },
                legend: {
                    display: this.config.legendDisplay,
                    labels: {
                        font: {
                            size: this.config.legendFontSize,
                            weight: 'bold',
                        }
                    },
                    position: 'bottom',
                    align: 'center',
                },
            },
        };

        this.options.plugins.tooltip = {
            callbacks: {
                title: this._tooltipTitleCallback,
                label: this._tooltipLabelCallback,
            }
        };
    }

    /**
     * Builds chart datasets list.
     */
    _buildDatasets() {
        let seriesList = [];
        if (this.series.hasOwnProperty('series')) {
            seriesList = this.series.series;
        } else {
            seriesList = [this.series];
        }

        this.datasets = [];
        let idx = 0;
        seriesList.forEach((s) => {
            let lineCol = this.config.lineColors[0];
            if (this.config.lineColors.length >= seriesList.length) {
                lineCol = this.config.lineColors[this.config.lineColors.length - seriesList.length + idx];
            }
            let areaCol = this.config.areaColors[0];
            if (this.config.areaColors.length >= seriesList.length) {
                areaCol = this.config.areaColors[this.config.areaColors.length - seriesList.length + idx];
            }
            let ds = {
                originalData: true,
                label: s.code_name,
                dataLabels: s.x_values !== undefined ? s.x_values : this.series.x_values,
                data: s.y_values,
                borderWidth: this.config.lineWidth,
                borderColor: !chartIsCake(this.type) ? lineCol : this.config.lineColors,
                backgroundColor: !chartIsCake(this.type) ? areaCol : this.config.areaColors,
                fill: chartHasArea(this.type),
                tension: chartHasLine(this.type) ? this.config.lineTension : 0,
            };
            this.datasets.push(ds);
            idx++;
        });
    }

    /**
     * Builds the chart and renders it within the assigned context (canvas).
     */
    render() {
        this._buildOptions();
        this._buildDatasets();

        import('chart.js').then((module) => {
            module.Chart.register(...module.registerables);
            this.chart = new module.Chart(
                this.context,
                {
                    type: this.type,
                    data: {
                        labels: this.series.x_values,
                        datasets: this.datasets,
                    },
                    options: this.options
                }
            )
            this.context.style.cssText += `opacity:${this.config.opacity}`;
        });
    }
}

class AxialChart extends BaseChart {
    valueAxis;

    _buildOptions() {
        super._buildOptions();

        this.options.scales = {};

        this.options.scales[this.valueAxis] = {
            ticks: {
                font: {
                    size: this.config.ticksFontSize,
                },
                beginAtZero: true,
                precision: this.config.valueAxisPrecision,
                padding: chartPadding,
            },
        };

        if (this.config.valueAxisForceRange) {
            this.options.scales[this.valueAxis].min = this.config.valueAxisMin;
            this.options.scales[this.valueAxis].max = this.config.valueAxisMax;
        }

        if (this.config.valueAxisSuffix) {
            this.options.scales[this.valueAxis].ticks.callback = (value, index, values) => {
                return value + this.config.valueAxisSuffix;
            };
        }
        if (this.config.valueAxisPrefix) {
            this.options.scales[this.valueAxis].ticks.callback = (value, index, values) => {
                return this.config.valueAxisPrefix + value;
            };
        }

        if (chartHasPoints(this.type)) {
            this.options.elements = {
                point: {
                    radius: this.config.pointRadius,
                }
            }
        }
    }

    _buildDatasets() {
        super._buildDatasets();

        if (this.config.highlightValueRangeDisplay) {
            if (this.config.highlightValueRangeMarkInOut) {
                this.datasets.forEach(ds => {
                    ds.segment = {
                        borderColor: (ctx) => {
                            if (ds.data[ctx.p0DataIndex] > this.config.highlightValueRangeMax ||
                                ds.data[ctx.p1DataIndex] > this.config.highlightValueRangeMax) {
                                return this.config.highlightValueRangeOutColor;
                            } else if (ds.data[ctx.p0DataIndex] < this.config.highlightValueRangeMin ||
                                ds.data[ctx.p1DataIndex] < this.config.highlightValueRangeMin) {
                                return this.config.highlightValueRangeOutColor;
                            } else {
                                return this.config.highlightValueRangeInColor;
                            }
                        }
                    }
                });
            }
            const dsMin = {
                type: 'line',
                label: 'lower th.',
                dataLabels: this.series.x_values,
                data: Array(this.series.x_values.length).fill(this.config.highlightValueRangeMin),
                borderWidth: this.config.highlightValueRangeLineWidth,
                borderColor: this.config.highlightValueRangeLineColor,
                backgroundColor: this.config.highlightValueRangeAreaColor,
                order: this.datasets.length,
            };
            this.datasets.push(dsMin);
            const dsMax = {
                type: 'line',
                label: 'upper th.',
                dataLabels: this.series.x_values,
                data: Array(this.series.x_values.length).fill(this.config.highlightValueRangeMax),
                borderWidth: this.config.highlightValueRangeLineWidth,
                borderColor: this.config.highlightValueRangeLineColor,
                backgroundColor: this.config.highlightValueRangeAreaColor,
                order: this.datasets.length,
                fill: '-1',
            };
            this.datasets.push(dsMax);
        }
    }
}

class CartesianChart extends AxialChart {
    labelAxis;
    constructor(type, context, series, config) {
        super(type, context, series, config);

        if (!this.config.flipAxes) {
            this.labelAxis = 'x';
            this.valueAxis = 'y';
        } else {
            this.labelAxis = 'y';
            this.valueAxis = 'x';
        }
    }

    _buildOptions() {
        super._buildOptions();

        this.options.indexAxis = this.labelAxis;

        this.options.scales[this.labelAxis] = {
            grid: {
                display: true,
                drawOnChartArea: false,
            },
            ticks: {
                font: {
                    size: this.config.ticksFontSize,
                }
            },
            title: {
                display: true,
                text: this.series.x_name,
                font: {
                    size: this.config.axisNameFontSize,
                    weight: 'bold',
                },
                padding: chartPadding,
            }
        };

        this.options.scales[this.valueAxis].grid = {
            display: false,
        };
        this.options.scales[this.valueAxis].title = {
            display: true,
            text: this.series.y_name,
            font: {
                size: this.config.axisNameFontSize,
                weight: 'bold',
            },
            padding: chartPadding,
        };
    }
}

class RadialChart extends AxialChart {
    constructor(type, context, series, config) {
        super(type, context, series, config);

        this.valueAxis = 'r';
    }

    _buildOptions() {
        super._buildOptions();

        this.options.scales[this.valueAxis].grid = {
            circular: true,
        };
        this.options.scales[this.valueAxis].beginAtZero = true;
        this.options.scales[this.valueAxis].pointLabels = {
            font: {
                size: this.config.ticksFontSize,
                weight: 'bold',
            },
        };
        this.options.scales[this.valueAxis].startAngle = 0;
    }
}

class LineChart extends CartesianChart {
    constructor(context, series, config) {
        super('line', context, series, config);
    }
}

class BubbleChart extends CartesianChart {
    constructor(context, series, config) {
        super('bubble', context, series, config);
    }
}

class BarChart extends CartesianChart {
    constructor(context, series, config) {
        super('bar', context, series, config);
    }
}

class BoxPlotChart extends CartesianChart {
    constructor(context, series, config) {
        super('boxplot', context, series, config);
    }

    _tooltipLabelCallback(ctx) {
        const fractionDigits = 3;
        let values = [
            `${ctx.dataset.dataLabels[ctx.dataIndex]}:`,
            `median: ${parseFloat(ctx.parsed.median.toFixed(fractionDigits))}`,
            `mean: ${parseFloat(ctx.parsed.mean.toFixed(fractionDigits))}`,
            `25% · 75% quantile: ${parseFloat(ctx.parsed.q1.toFixed(fractionDigits))} · ${parseFloat(ctx.parsed.q3.toFixed(fractionDigits))}`,
            `min · max: ${parseFloat(ctx.parsed.whiskerMin.toFixed(fractionDigits))} · ${parseFloat(ctx.parsed.whiskerMax.toFixed(fractionDigits))}`,
            `extreme min · max: ${parseFloat(ctx.parsed.min.toFixed(fractionDigits))} · ${parseFloat(ctx.parsed.max.toFixed(fractionDigits))}`,
        ];
        return values;
    }

    render() {
        this._buildOptions();
        this._buildDatasets();

        this.datasets.forEach(ds => {
            if (ds.originalData) {
                ds.outlierRadius = 3;
            }
        });

        Promise.all([
            import('chart.js'),
            import('@sgratzl/chartjs-chart-boxplot'),
        ]).then(([chartModule, boxplotModule]) => {
            chartModule.Chart.register(...chartModule.registerables);
            this.chart = new boxplotModule.BoxPlotChart(
                this.context,
                {
                    data: {
                        labels: this.series.x_values,
                        datasets: this.datasets,
                    },
                    options: this.options
                }
            )
            this.context.style.cssText += `opacity:${this.config.opacity}`;
        });
    }
}

class RadarChart extends RadialChart {
    constructor(context, series, config) {
        super('radar', context, series, config);
    }
}

class PieChart extends BaseChart {
    constructor(context, series, config) {
        super('pie', context, series, config);
    }
}

class DoughnutChart extends BaseChart {
    constructor(context, series, config) {
        super('doughnut', context, series, config);
    }
}


const SeriesChart = {
    'line': LineChart,
    'bubble': BubbleChart,
    'bar': BarChart,
    'boxplot': BoxPlotChart,
    'radar': RadarChart,
    'pie': PieChart,
    'doughnut': DoughnutChart,
}


export const drawSeriesChart = (
    type = 'line',
    context,
    series,
    config
) => {
    let ChartClass = SeriesChart[type];
    let chart = new ChartClass(context, series, config);
    chart.render();
}
